* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain; /* Prevent pull-to-refresh */
}

a,
p,
span,
b,
label,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito Sans', sans-serif !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.h-50 {
  height: 50%;
}

.Toastify__toast-body > div > p {
  word-break: break-word;
}

.Toastify__toast-container {
  width: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.text-nowrap {
  white-space: nowrap !important;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-between {
  justify-content: space-between;
}

/* margin */

.mt-0 {
  margin: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 12px !important;
}
.mt-4 {
  margin-top: 16px !important;
}

.mb-2 {
  margin-bottom: 0px !important;
}
.ml-3 {
  margin-left: 0px !important;
}
.mr-4 {
  margin-right: 0px !important;
}
.m-0 {
  margin: 0px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.mb-3 {
  margin-bottom: 12px !important;
}
.mb-4 {
  margin-bottom: 16px !important;
}

.mr-1 {
  margin-right: 4px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mr-3 {
  margin-right: 12px !important;
}
.mr-4 {
  margin-right: 16px !important;
}

.ml-1 {
  margin-left: 4px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.ml-3 {
  margin-left: 12px !important;
}
.ml-4 {
  margin-left: 16px !important;
}

.mx-1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-2 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.mx-3 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-1 {
  margin: 4px !important;
}
.m-2 {
  margin: 8px !important;
}
.m-3 {
  margin: 12px !important;
}
.m-4 {
  margin: 16px !important;
}

/* padding */
.pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}

.pt-1 {
  padding-top: 4px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-3 {
  padding-top: 12px !important;
}
.pt-4 {
  padding-top: 16px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pb-3 {
  padding-bottom: 12px !important;
}
.pb-4 {
  padding-bottom: 16px !important;
}

.pr-1 {
  padding-right: 4px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pr-3 {
  padding-right: 12px !important;
}
.pr-4 {
  padding-right: 16px !important;
}

.pl-1 {
  padding-left: 4px !important;
}
.pl-2 {
  padding-left: 8px !important;
}
.pl-3 {
  padding-left: 12px !important;
}
.pl-4 {
  padding-left: 16px !important;
}

.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 4px !important;
}
.p-2 {
  padding: 8px !important;
}
.p-3 {
  padding: 12px !important;
}
.p-4 {
  padding: 16px !important;
}

/* Common */
/* Flexbox utility for columns */
.row {
  display: flex;
  flex-wrap: wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Define column widths from 1 to 12 */
[class*='col-'] {
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

/* Specific widths for each column size */
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
